import { CondensedButton } from '@instructure/ui-buttons';
import { IconWarningLine } from '@instructure/ui-icons';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';

import IconText from '../../uiCommon/components/IconText';
import { JOB_STATES } from '../constants/job';
import { Job } from '../redux/job';

const hasRosterImportErrors = (job: Job): boolean => {
  const rosterImports = get(job, 'data.rosterImports', []);

  return Array.isArray(rosterImports)
    ? rosterImports.some((rosterImport) => get(rosterImport, 'state.errors') > 0)
    : false;
};

type Props = {
  job: Job;
  dataTag?: string;
  onClick?: () => void;
};

/**
 * Icon and text for job status.
 */
class JobStatus extends Component<Props> {
  renderText(text: string): ReactNode {
    if (this.props.onClick) {
      const props: { onClick?: () => void; 'data-button'?: string } = {
        onClick: this.props.onClick,
      };

      if (this.props.dataTag) {
        props['data-button'] = this.props.dataTag;
      }

      return <CondensedButton {...props}>{text}</CondensedButton>;
    }
    return text;
  }

  render(): ReactNode {
    const { job } = this.props;
    const found = JOB_STATES[job.state];
    const text = found ? found.title : job.state;
    let icon = found ? <found.Icon color={found.color} /> : null;

    // Change the icon if there's errors
    if (job.data.errors || hasRosterImportErrors(job)) {
      icon = <IconWarningLine color={'error'} />;
    }

    return <IconText icon={icon}>{this.renderText(text)}</IconText>;
  }
}

export default JobStatus;

import get from 'lodash/get';

import {
  ActionThunk,
  fetch,
  FetchOptions,
  getActionThunk,
  getNestedReducer,
  NestedAsyncState,
} from '../../uiCommon/redux/async';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';

export const downloadURL = (url: string, filename: string): void => {
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
  a.remove();
};

export const download = <T>(filename: string, options: FetchOptions): ActionThunk<T> => {
  const thunk = async () => {
    const response = await fetch(options);

    const url = get(response.data, 'url') || window.URL.createObjectURL(new Blob([response.data]));

    downloadURL(url, filename);
  };

  return getActionThunk(DOWNLOAD_FILE, thunk, filename);
};

export type DownloadState = NestedAsyncState<void>;

export default getNestedReducer(DOWNLOAD_FILE);

import browserLocale from 'browser-locale';
import I18n from 'i18n-js';
import i18nliner from 'i18nliner/dist/lib/extensions/i18n_js';

import translations from '../../config/locales';

i18nliner(I18n);

I18n.translations = {
  ...I18n.translations,
  ...translations,
};

const DEFAULT_LOCALE = 'en';

export const getLocale = (locale?: string): string => {
  if (!locale) {
    return DEFAULT_LOCALE;
  }

  if (translations.hasOwnProperty(locale)) {
    return locale;
  }

  const prefix = locale.substring(0, locale.indexOf('-'));

  if (translations.hasOwnProperty(prefix)) {
    return prefix;
  }

  return DEFAULT_LOCALE;
};

I18n.locale = getLocale(browserLocale());
I18n.missingBehaviour = 'guess';

export default I18n;

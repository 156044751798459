import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';

import JobQueueName, { QUEUES } from '../../../uiCommon/components/JobQueueName';
import { Job } from '../../redux/job';
import ConnectedDownloadButton, { DownloadOptions } from '../DownloadButton';
import { compare } from '../util';

import SelectableTable, { Header, Row } from './SelectableTable';

export type Props = {
  job: Job;
  onDownload: (options: DownloadOptions) => void;
};

export default class LogList extends Component<Props> {
  renderQueue = (queue: unknown): ReactNode => <JobQueueName queue={queue as string} />;

  renderCount = (count: unknown): string =>
    I18n.toNumber(count as number, {
      precision: 0,
    });

  renderDownloadButton = (queue: unknown, job: Job): ReactNode => {
    const { onDownload } = this.props;
    const path = get(job.data.logs, [queue as string, 'path'], '');
    const filename = path.substring(path.indexOf('logs/'));

    return filename ? (
      <ConnectedDownloadButton prefix={job.data.prefix} filename={filename} onClick={onDownload}>
        {I18n.t('Logs')}
      </ConnectedDownloadButton>
    ) : null;
  };

  getHeaders(): Array<Header<Job>> {
    return [
      {
        key: 'stage',
        text: I18n.t('Stage'),
        renderCell: this.renderQueue,
      },
      {
        key: 'info',
        text: I18n.t('Info'),
        renderCell: this.renderCount,
        textAlign: 'end',
      },
      {
        key: 'warning',
        text: I18n.t('Warning'),
        renderCell: this.renderCount,
        textAlign: 'end',
      },
      {
        key: 'error',
        text: I18n.t('Error'),
        renderCell: this.renderCount,
        textAlign: 'end',
      },
      {
        key: 'other',
        text: I18n.t('Action'),
        renderCell: this.renderDownloadButton,
        textAlign: 'end',
      },
    ];
  }

  getRows(): Array<Row<Job>> {
    const { job } = this.props;
    const logs = get(job, 'data.logs', {});
    const queues = Object.keys(logs).sort((a, b) =>
      compare(get(QUEUES, [a, 'stage']), get(QUEUES, [b, 'stage'])),
    );

    return queues.map((queue) => {
      return {
        key: queue,
        src: job,
        values: {
          stage: queue,
          info: get(logs, [queue, 'counts', 'info'], 0),
          warning: get(logs, [queue, 'counts', 'warn'], 0),
          error: get(logs, [queue, 'counts', 'error'], 0),
          other: queue,
        },
      };
    });
  }

  render(): ReactNode {
    return (
      <SelectableTable caption={I18n.t('Logs')} headers={this.getHeaders()} rows={this.getRows()} />
    );
  }
}

import { IntegrationDetails } from '../integrationDetails';
import { RolloverStatus } from '../rolloverStatus';

export type RolloverDateDto = {
  id: number;
  rolloverName: string;
  syncPauseDate: string;
  syncResumeDate: string;
  status: RolloverStatus;
  integrationDetails: IntegrationDetails;
  nextDiffingPhraseSeed: string;
  affectedSchedules: Array<string>;
  createdAt: string;
  updatedBy: string;
  notes?: string;
};

export enum RolloverSortKey {
  ByIntegrationName = 'integrationName',
  ByRolloverName = 'rolloverName',
  BySyncPauseDate = 'syncPauseDate',
  BySyncResumeDate = 'syncResumeDate',
  ByStatus = 'status',
}

import get from 'lodash/get';
import React, { Fragment } from 'react';

import { QUEUES } from '../../uiCommon/components/JobQueueName';
import Log from '../../uiCommon/components/Log';
import { Job, JobData, JobError, LogItem } from '../redux/job';

import { compare } from './util';

export type Props = {
  job: Job;
};

const LogMessages: React.FC<Props> = (props) => {
  const { job } = props;

  const historyLogs = job.history
    .filter((log) => log.what === 'logged')
    .map((log) => {
      return {
        level: log.level || 'info',
        message: log.message || '',
      };
    });

  const { message, statusCode } = get<JobData, 'error', JobError>(job.data, 'error', {});
  const errorMessage = message || statusCode;

  const firstQueue = Object.keys(get(job.data, 'logs', {}))
    .sort((a, b) => compare(get(QUEUES, [a, 'stage']), get(QUEUES, [b, 'stage']), false))
    .pop();
  const logs: LogItem[] = get(job.data, ['logs', firstQueue as keyof JobData, 'logs'], []);

  return (
    <Fragment>
      {historyLogs.map((log, index) => (
        <Log key={String(index)} log={log} />
      ))}
      {errorMessage && (
        <Log
          log={{
            level: 'error',
            message: `${errorMessage}`,
          }}
        />
      )}
      {Array.isArray(logs) && logs.map((log, index) => <Log key={String(index)} log={log} />)}
    </Fragment>
  );
};

export default LogMessages;

export type SimpleAction<T> = {
  type: string;
  data: T;
  id?: string;
};

export const getSimpleAction = <T>(name: string, data: T, id?: string): SimpleAction<T> => {
  return {
    type: name,
    data,
    id,
  };
};

export type SimpleReducer<T> = (state: T, action: SimpleAction<T>) => T;

export const getSimpleReducer = <T>(name: string, defaultState: T): SimpleReducer<T> => {
  return (state = defaultState, action) => {
    switch (action.type) {
      case name:
        return action.data;
      default:
        return state;
    }
  };
};

export type NestedState<T> = {
  [id: string]: T;
};

export type NestedReducer<T> = (state: NestedState<T>, action: SimpleAction<T>) => NestedState<T>;

export const getNestedReducer = <T>(name: string): NestedReducer<T> => {
  return (state = {}, action) => {
    if (action.type === name && action.id) {
      return {
        ...state,
        [action.id]: action.data,
      };
    }
    return state;
  };
};

import { Button } from '@instructure/ui-buttons';
import { IconDownloadSolid } from '@instructure/ui-icons';
import get from 'lodash/get';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux';
import { LtiTabState } from '../redux/types';

type MappedProps = {
  pending: boolean;
};

export type DownloadOptions = {
  key: string;
  prefix: string;
  filename: string;
};

type OwnProps = {
  prefix: string;
  filename: string;
  onClick: (options: DownloadOptions) => void;
  children: ReactNode;
};

type Props = MappedProps & OwnProps;

export class DownloadButton extends Component<Props> {
  handleClick = (): void => {
    const { onClick, filename, prefix } = this.props;

    onClick({
      key: filename,
      prefix,
      filename,
    });
  };

  render(): ReactNode {
    const { pending, children } = this.props;

    return (
      <Button
        onClick={this.handleClick}
        renderIcon={IconDownloadSolid}
        disabled={pending}
        size="small"
      >
        {children}
      </Button>
    );
  }
}

export const mapStateToProps = (state: LtiTabState | RootState, props: OwnProps): MappedProps => {
  return {
    pending: get(state.downloads, [props.filename, 'pending'], false),
  };
};

export default connect(mapStateToProps)(DownloadButton);
